:root {
  --primary-accent: #00e6b8;
  --secondary-accent: #7c4dff;
  --dark-bg: #0f172a;
  --card-bg: #1e293b;
  --text-primary: #f8fafc;
}

.portfolio-section {
  padding: 6rem 2rem;
  background: var(--dark-bg);
  position: relative;
}

.portfolio-header {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto 4rem;
}

.section-title {
  font-size: 2.75rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.stats-badge {
  background: rgba(255,255,255,0.1);
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  font-size: 1.1rem;
  backdrop-filter: blur(10px);
}

.section-subtitle {
  color: #94a3b8;
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
}

.filter-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.filter-buttons button {
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(255,255,255,0.1);
  color: #94a3b8;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-buttons button.active {
  background: var(--primary-accent);
  color: var(--dark-bg);
  border-color: transparent;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: -2rem;
}

.portfolio-card {
  background: var(--card-bg);
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 80%;
}

.portfolio-card:hover {
  transform: translateY(-0.5rem);
}

.card-inner {
  position: relative;
  padding-top: 75%; /* 4:3 aspect ratio */
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(180deg, rgba(15,23,42,0.9) 0%, rgba(15,23,42,0.7) 100%);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.portfolio-card:hover .card-overlay {
  opacity: 1;
}

.client-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.client-logo {
  height: 40px;
  width: auto;
  filter: brightness(0) invert(1);
}

.client-info h3 {
  color: var(--text-primary);
  margin: 0;
  font-size: 1.1rem;
}

.tech-stack {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.tech-pill {
  background: rgba(255,255,255,0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.project-details {
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 1rem;
}

.project-results {
  color: #cbd5e1;
  font-size: 0.9rem;
  margin: 0 0 1rem;
}

.case-study-link {
  color: var(--primary-accent);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.load-more {
  display: block;
  margin: 3rem auto 0;
  background: var(--primary-accent);
  color: var(--dark-bg);
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.count-badge {
  background: var(--dark-bg);
  color: var(--primary-accent);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-left: 0.75rem;
}

.portfolio-footer {
  text-align: center;
  margin-top: 4rem;
}

.cta-text {
  color: #94a3b8;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.cta-primary {
  background: var(--primary-accent);
  color: var(--dark-bg);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 600;
}

.cta-secondary {
  background: rgba(255,255,255,0.05);
  color: var(--primary-accent);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  border: 1px solid var(--primary-accent);
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
    flex-direction: column;
  }
  
  .portfolio-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
  
  .filter-buttons {
    flex-wrap: wrap;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
}