.pricing-hero-section  {
  background: radial-gradient(circle at 75% 50%, #0d1a24 0%, #08101a 100%);
  padding: 8rem 2rem 6rem;
  position: relative;
  overflow: hidden;
}



.pricing-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.pricing-hero-content h1 {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.pricing-gradient-text {
  background: linear-gradient(135deg, #5fd0c5 0%, #8be9fd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-hero-subtitle {
  font-size: 1.4rem;
  color: #c3d8e6;
  margin: 2rem auto;
  max-width: 800px;
  line-height: 1.6;
}

.pricing-highlight {
  background: linear-gradient(180deg, transparent 65%, rgba(95,208,197,0.3) 65%);
  font-weight: 600;
}

.pricing-value-propositions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.pricing-prop-card {
  background: rgba(26, 36, 47, 0.6);
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 16px;
  border: 1px solid rgba(95,208,197,0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-prop-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(95,208,197,0.1);
}

.pricing-prop-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
  background-size: contain;
}

.pricing-prop-icon.security { background-image: url('../assets/svg/shield-icon.svg'); }
.pricing-prop-icon.performance { background-image: url('../assets/svg/speedometer-icon.svg'); }
.pricing-prop-icon.support { background-image: url('../assets/svg/support-icon.svg'); }

.pricing-prop-card h3 {
  color: #5fd0c5;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
}

.pricing-prop-card p {
  color: #a4b8c7;
  font-size: 1rem;
  line-height: 1.5;
}

.pricing-trust-badges {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
  flex-wrap: wrap;
}

.pricing-badge-container {
  text-align: center;
  transition: transform 0.3s ease;
}

.pricing-badge-container:hover {
  transform: scale(1.05);
}

.pricing-trust-badges img {
  height: 40px;
  width: auto;
  filter: grayscale(1);
  transition: filter 0.3s ease;
}

.pricing-trust-badges img:hover {
  filter: grayscale(0);
}

.pricing-badge-container span {
  display: block;
  margin-top: 1rem;
  color: #7c9eb2;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .pricing-hero {
    padding: 6rem 1.5rem 4rem;
  }
  
  .pricing-hero-content h1 {
    font-size: 2.5rem;
    text-align: left;
  }
  
  .pricing-hero-subtitle {
    font-size: 1.2rem;
  }
  
  .pricing-value-propositions {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
  
  /* Pricing Table */
  .pricing-table {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 2rem 0;
  }
  
  /* Pricing Section Title */
  .pricing-section-title {
    text-align: center;
    font-size: 2rem;
    color: #fff;
  }
  .pricing-section-subtitle {
    text-align: center;
  }
  /* Pricing Card */
  .pricing-card {
    background: linear-gradient(180deg, #24303e, #1f2a37);
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    padding: 20px;
    text-align: center;
    color: #e4e8eb;
    flex: 1 1 calc(33.333% - 20px);
    max-width: 400px;
    min-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  }
  
  /* Price Styling */
  .price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #5fd0c5;
    margin-bottom: 1rem;
  }
  
  /* Features Table */
  .features-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  .features-table th,
  .features-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #3a4a57;
    color: #e4e8eb;
  }
  .description{
    display: none;
  }
  
  .features-table th {
    font-size: 1rem;
  }
  
  .features-table td {
    font-size: 0.9rem;
  }
  
  .checkmark {
    color: #5fd0c5;
    font-weight: bold;
  }
  
  .cross {
    color: #ff5c5c;
    font-weight: bold;
  }
  
  /* Delivery Time */
  .delivery-time {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pricing-card {
      flex: 1 1 calc(100% - 20px);
      max-width: 100%;
    }
  
    .features-table th,
    .features-table td {
      font-size: 0.85rem;
    }
    .hero-content h1 {
      font-size: 2rem;

    }
    
  }
  /* Get Started Button */
.get-started-button {
    background: linear-gradient(180deg, #5fd0c5, #137a74);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .get-started-button:hover {
    background: linear-gradient(180deg, #137a74, #0a504e);
    transform: translateY(-2px);
  }
  
  .get-started-button:active {
    transform: translateY(0);
    background: linear-gradient(180deg, #0a504e, #137a74);
  }
  
  /* FAQ Section */
  .pricing-faq-section {
    max-width: 1200px;
    margin: 4rem auto;
    padding: 0 20px;
  }
  
  .faq-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .faq-card{
    color: #fff;
  }
  
  .faq-card {
    background: #24303e;
    padding: 1.5rem;
    border-radius: 8px;
    border-left: 4px solid #5fd0c5;
  }
  
  /* Final CTA */
  .final-cta {
    text-align: center;
    padding: 4rem 20px;
    background: linear-gradient(180deg, #1a242f, #24303e);
    border-radius: 15px;
  }

  .final-cta h2 {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .cta-button {
    background: linear-gradient(180deg, #5fd0c5, #137a74);
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin: 2rem 0;
    transition: transform 0.3s ease;
  }
  
  .cta-button:hover {
    transform: scale(1.05);
  }
  
  .trust-badges {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .trust-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #5fd0c5;
  }
  

  
  /* FAQ Mobile Fix */
  @media (max-width: 768px) {
    .faq-grid {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  
    .faq-card {
      margin: 0 10px;
    }
  }
  
  /* Final CTA Mobile Fix */
  @media (max-width: 768px) {
    .final-cta {
      padding: 2rem 10px;
    }
  
    .final-cta h2 {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  
    .cta-button {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
    }
  
    .trust-badges {
      flex-direction: column;
      gap: 1rem;
    }
  
    .trust-item {
      font-size: 0.9rem;
    }
  }
  
  /* General Mobile Improvements */
  @media (max-width: 480px) {
    .pricing-section-title {
      font-size: 1.5rem;
      padding: 0 10px;
    }
  
    .pricing-subtitle {
      font-size: 0.9rem;
      padding: 0 10px;
    }
  
    .pricing-card {
      min-width: 280px;
      margin: 0 10px;
    }
  
    .features-table th,
    .features-table td {
      padding: 6px;
      font-size: 0.8rem;
    }
  
    .delivery-time {
      font-size: 0.8rem;
    }
  }
  
  /* Horizontal Scroll Prevention */
  @media (max-width: 480px) {
    .pricing-section {
      overflow-x: hidden;
    }
    
    body {
      overflow-x: hidden;
    }
  }

  