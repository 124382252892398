.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #f8f9fa; /* Light background */
    color: #343a40; /* Dark text */
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .not-found-title {
    font-size: 8rem;
    font-weight: bold;
    color: #007bff; /* Primary color */
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1.2rem;
    color: #fff;
    background: #007bff; /* Primary color */
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .not-found-link:hover {
    background: #0056b3; /* Darker primary color */
  }