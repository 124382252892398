/* General Styles */
body {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(180deg, #1c1f26 0%, #2a2d35 100%);
    height: 100%; /* Ensure the body covers the viewport height */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */
    scroll-behavior: smooth; /* Smooth scrolling for anchors */
    box-sizing: border-box; /* Include padding and border in element dimensions */
  }
  
  /* Ensure all elements follow box-sizing rules */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  
  /* Images - Ensure they don't cause overflow */
  img {
    max-width: 100%; /* Restrict image width to the parent container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Prevent extra space below images */
  }
  