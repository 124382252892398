/* Services Page - Royol Technology */

:root {
  --royol-primary: #00e6b8;
  --royol-secondary: #7c4dff;
  --royol-dark-bg: #101820;
  --royol-card-bg: #1f2a37;
  --royol-text-light: #f8fafc;
  --royol-hover: #5fd0c5;
}

/* Hero Section */
.royol-services-hero {
  background: #0f172a;
  text-align: center;
  padding: 5rem 2rem;
  color: white;
}

.royol-services-hero-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.royol-services-hero-content span {
  color: var(--royol-hover);
}

.royol-services-cta {
  display: inline-block;
  background: var(--royol-primary);
  color: white;
  padding: 1rem 2rem;
  border-radius: 25px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  font-weight: 600;
}

.royol-services-cta:hover {
  background: var(--royol-hover);
  transform: scale(1.05);
}

/* Stats Section */
.royol-services-stats {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  background: var(--royol-dark-bg);
  text-align: center;
}

.royol-services-stat-card {
  text-align: center;
}

.royol-services-stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--royol-primary);
}

.royol-services-stat-label {
  font-size: 1rem;
  margin-top: 0.5rem;
}

/* Services Section */
.royol-services-section {
  padding: 6rem 2rem;
  background-color: var(--royol-dark-bg);
  color: #fff;
}

.royol-services-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

/* Services Grid */
.royol-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Service Card */
.royol-service-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: var(--royol-card-bg);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.royol-service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
}

/* Service Background */
.royol-service-background {
  height: 200px;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.royol-service-card:hover .royol-service-background {
  opacity: 1;
}

/* Service Content */
.royol-service-content {
  padding: 2rem;
  background: rgba(31, 42, 55, 0.9);
}

.royol-service-title {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--royol-hover);
}

.royol-service-description {
  font-size: 0.9rem;
  color: #d6d6d6;
}

.royol-service-cta {
  display: inline-block;
  background: var(--royol-hover);
  color: #1f2a37;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s ease;
  font-size: 1rem;
}

.royol-service-cta:hover {
  background: #7be3d9;
  transform: scale(1.05);
}

/* Marketing Showcase */
.services-marketing-showcase {
  padding: 6rem 0;
  background: #131a22;
  text-align: center;
}

.services-marketing-showcase h2 {
  font-size: 2.5rem;
  color: #5fd0c5;
  margin-bottom: 2rem;
}

.services-results-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 800px;
  margin: auto;
}

.services-result-card {
  background: #1f2a37;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.services-result-card .result-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #5fd0c5;
  margin-bottom: 0.5rem;
}

/* FAQ Section */
.services-faq-section {
  padding: 4rem 0;
  background: #1f2a37;
  text-align: center;
}

.services-faq-section h2 {
  font-size: 2.5rem;
  color: #5fd0c5;
  margin-bottom: 2rem;
}

.services-faq-grid {
  max-width: 800px;
  margin: auto;
}

.services-faq-card {
  background: #131a22;
  border: 1px solid #5fd0c5;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.services-faq-card.active {
  box-shadow: 0 5px 15px rgba(95, 208, 197, 0.1);
}

.services-faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.services-faq-question h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #5fd0c5;
}

.services-toggle-icon {
  font-size: 1.5rem;
  color: #5fd0c5;
  font-weight: 700;
}

.services-faq-answer {
  padding-top: 1rem;
  color: #d6d6d6;
  line-height: 1.6;
}

/* Tech Stack Section */
.services-tech-stack-section {
  padding: 4rem 0;
  background: #101820;
  text-align: center;
}

.services-tech-stack-section h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #5fd0c5;
}

.services-logo-carousel {
  display: flex;
  justify-content: center;
  gap: 4rem;
  animation: scroll 30s linear infinite;
  padding: 2rem 0;
}

.services-logo-item img {
  height: 50px;
  width: auto;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.services-logo-item:hover img {
  filter: grayscale(0%);
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

/* Responsive Design */
@media (max-width: 1068px) {
  #services-section {
    padding: 4rem 2rem;
  }

  .services-results-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .creative-grid {
    grid-template-columns: 1fr;
  }

  .services-faq-card {
    margin: 0 1rem 1rem;
  }

  .services-logo-carousel {
    gap: 2rem;
    animation: none;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  #services-section {
    padding: 2rem 1rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .creative-title {
    font-size: 1.2rem;
  }

  .creative-description {
    font-size: 0.9rem;
  }
}

/* Why Choose Us Section */
.why-choose-us {
  background: #131a22;
  color: #fff;
  text-align: center;
  padding: 6rem 2rem;
}

.choose-container {
  max-width: 1100px;
  margin: 0 auto;
}

.choose-subtext {
  font-size: 1.2rem;
  color: #d6d6d6;
  margin-bottom: 3rem;
}

.choose-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.choose-card {
  background: #1f2a37;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.choose-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
}

.choose-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #5fd0c5;
}

.choose-card h3 {
  font-size: 1.5rem;
  color: #5fd0c5;
  margin-bottom: 0.5rem;
}

.choose-card p {
  font-size: 1rem;
  color: #d6d6d6;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .choose-grid {
    grid-template-columns: 1fr;
  }

  .choose-card {
    padding: 1.5rem;
  }

  .choose-icon {
    font-size: 2.5rem;
  }
}