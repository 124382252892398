/* Footer Styling */
footer {
    background: #1f2a37; /* Dark background for the footer */
    color: #f0f0f0;
    padding: 40px 5%;
    font-family: 'Montserrat', sans-serif;
  }
  
  .footer-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjust layout for smaller screens */
    margin-bottom: 30px;
    gap: 20px; /* Adds spacing between sections */
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin-right: 20px;
  }
  
  .footer-section h4 {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 1.2em;
    text-transform: uppercase;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #a8a8a8;
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease, text-shadow 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #5fd0c5;
    text-shadow: 0 0 5px #5fd0c5; /* Glow effect on hover */
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
    color: #c5c9cd;
  }
  
  .legal-links {
    display: flex;
    gap: 20px;
  }
  
  .legal-links a {
    color: #a8a8a8;
    text-decoration: none;
    font-size: 0.95rem;
    transition: color 0.3s ease, text-shadow 0.3s ease;
  }
  
  .legal-links a:hover {
    color: #5fd0c5;
    text-shadow: 0 0 5px #5fd0c5;
  }
  
  /* Social Links Styling */
  .social-links {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  .social-links a {
    color: #a8a8a8;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .social-links a:hover {
    color: #5fd0c5;
    transform: scale(1.1);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-main {
      flex-direction: column;
      gap: 30px;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .legal-links {
      gap: 10px;
    }
  }
  /* New Footer CSS Additions */
.cities-container ul {
  columns: 2;
  column-gap: 20px;
  margin-bottom: 15px;
}

.online-services {
  margin: 15px 0;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  text-align: center;
}

.online-services a {
  color: #5fd0c5;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.online-services a:hover {
  color: #7be3d9;
  text-shadow: 0 0 8px rgba(95, 208, 197, 0.4);
}

.view-all-link {
  display: block;
  text-align: center;
  color: #a8a8a8;
  text-decoration: none;
  font-size: 0.95rem;
  margin-top: 10px;
}

.view-all-link:hover {
  color: #5fd0c5;
}

.virtual-office {
  margin-top: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
}

.virtual-office h5 {
  color: #5fd0c5;
  margin-bottom: 8px;
}

.newsletter form {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.newsletter input {
  flex: 1;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #444;
  background: #1f2a37;
  color: #fff;
}

.newsletter button {
  background: #5fd0c5;
  color: #1f2a37;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.newsletter button:hover {
  background: #7be3d9;
}

@media (max-width: 768px) {
  .cities-container ul {
    columns: 1;
  }
  
  .newsletter form {
    flex-direction: column;
  }
}