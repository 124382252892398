/* CSS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.success-section {
    color: #e4e8eb;
    padding: 50px 20px;
    text-align: center;
    height: 80vh;
    margin-top: 10rem;
}

.success-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #1f2a37;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.success-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #5fd0c5;
}

.success-container p {
    margin-bottom: 15px;
    color: #e4e8eb;
}

button {
    background: linear-gradient(177deg, rgba(95,208,197,1) 0%, rgba(137,227,218,1) 100%);
    color: #101820;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.2s;
}

button:hover {
    background: linear-gradient(177deg, rgba(137,227,218,1) 0%, rgba(95,208,197,1) 100%);
    transform: translateY(-3px);
    color: #ffffff;
}

button:disabled {
    background: #aaa;
    cursor: not-allowed;
}
