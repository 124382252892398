/* Booking Page - Royol Technology */

:root {
    --royol-primary: #00e6b8;
    --royol-secondary: #7c4dff;
    --royol-dark-bg: #0f172a;
    --royol-card-bg: #1e293b;
    --royol-text-light: #f8fafc;
    --royol-hover: #00d4a7;
  }
  
  /* Booking Hero Section */
  .royol-booking-hero {
    background: linear-gradient(to right, var(--royol-primary), var(--royol-secondary));
    text-align: center;
    padding: 5rem 2rem;
    color: white;
  }
  
  .royol-booking-hero-content h1 {
    font-size: 3rem;
    font-weight: 700;
  }
  
  .royol-booking-hero-content span {
    color: var(--royol-hover);
  }
  
  .royol-booking-hero-content p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  
  /* Booking Form Section */
  .royol-booking-form-section {
    text-align: center;
    padding: 4rem 2rem;
  }
  
  .royol-booking-subtext {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #cbd5e1;
  }
  
  .royol-booking-form {
    background: var(--royol-card-bg);
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .royol-form-group {
    margin-bottom: 1.5rem;
  }
  
  .royol-form-group input,
  .royol-form-group select,
  .royol-form-group textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #2d3748;
    color: white;
  }
  
  .royol-form-group select {
    cursor: pointer;
  }
  
  .royol-form-group textarea {
    height: 100px;
  }
  
  .royol-booking-button {
    background: var(--royol-primary);
    color: white;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 5px;
    font-weight: bold;
    border: none;
    width: 100%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  
  .royol-booking-button:hover {
    background: var(--royol-hover);
  }
  
  .royol-booking-success {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: var(--royol-primary);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .royol-booking-hero-content h1 {
      font-size: 2rem;
    }
  
    .royol-booking-form {
      padding: 1.5rem;
    }
  }