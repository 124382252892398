/* Modern Contact Page Styles */
.contact-modern-hero {
  background: linear-gradient(135deg, #0f172a, #1e293b);
  padding: 6rem 2rem;
  color: #f8fafc;
 
}

.contact-modern-container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-modern-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contact-modern-highlight {
  color: #00e6b8;
  display: block;
  
}

.contact-modern-subtitle {
  font-size: 1.25rem;
  color: #94a3b8;
  margin-bottom: 3rem;
}

.contact-modern-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  
}

.contact-modern-info {
  background: rgba(255,255,255,0.05);
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(0,230,184,0.1);
}

.contact-info-card {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(255,255,255,0.03);
  border-radius: 0.5rem;
}

.info-card-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-modern-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group-dual {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.modern-form-input {
  width: 100%;
  padding: 1rem;
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(255,255,255,0.1);
  border-radius: 0.5rem;
  color: #f8fafc;
  transition: all 0.3s ease;
}

.modern-form-input:focus {
  border-color: #00e6b8;
  box-shadow: 0 0 15px rgba(0,230,184,0.1);
}

.modern-form-textarea {
  height: 150px;
  resize: vertical;
}

.modern-form-submit {
  background: linear-gradient(45deg, #00e6b8, #7c4dff);
  color: #0f172a;
  padding: 1.25rem 2rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.modern-form-submit:hover {
  transform: translateY(-2px);
}

.contact-modern-success {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1e293b;
  padding: 2rem 3rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
  border: 1px solid #00e6b8;
}

.success-checkmark {
  font-size: 3rem;
  color: #00e6b8;
  margin-bottom: 1rem;
}

.client-logos {
  width: 100%;
  margin-top: 2rem;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .contact-modern-grid {
    grid-template-columns: 1fr;
  }
  
  .contact-modern-title {
    font-size: 2rem;
  }
  
  .form-group-dual {
    grid-template-columns: 1fr;
  }
}