/* Modern About Page Styles */
.modern-about-root {
  --modern-primary: #00e6b8;
  --modern-dark: #0f172a;
  --modern-light: #f8fafc;
}

/* Hero Section */
.modern-about-hero {
  background: linear-gradient(135deg, #0f172a, #1e293b);
  padding: 8rem 2rem;
  position: relative;
  overflow: hidden;
}

.modern-hero-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.modern-hero-gradient {
  background: linear-gradient(45deg, #00e6b8, #7c4dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  font-size: 4rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
}

.modern-hero-subtext {
  display: block;
  font-size: 2rem;
  color: #94a3b8;
  margin-bottom: 3rem;
}

.modern-hero-year {
  color: #00e6b8;
  margin-left: 1rem;
}

.modern-hero-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.modern-stat-card {
  background: rgba(255,255,255,0.05);
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid rgba(0,230,184,0.1);
  transition: transform 0.3s ease;
}

.modern-stat-card:hover {
  transform: translateY(-5px);
}

.modern-stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #00e6b8, #7c4dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modern-client-wall {
  margin-top: 4rem;
  position: relative;
}

.client-scroll {
  display: flex;
  animation: scroll 30s linear infinite;
  width: max-content;
  color: whitesmoke;
}

.client-badge {
  background: rgba(255,255,255,0.05);
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  margin-right: 1rem;
  font-size: 0.9rem;
  white-space: nowrap;
}

@keyframes scroll {
  0% { transform: translateX(0) }
  100% { transform: translateX(-50%) }
}

.modern-cta-primary {
  position: relative;
  background: linear-gradient(45deg, #00e6b8, #7c4dff);
  color: #0f172a;
  padding: 1.25rem 2.5rem;
  border-radius: 0.5rem;
  font-weight: 700;
  overflow: hidden;
  border: none;
  transition: transform 0.3s ease;
}

.cta-aura {
  position: absolute;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, rgba(255,255,255,0.15) 0%, transparent 70%);
  animation: aura-pulse 3s infinite;
}

@keyframes aura-pulse {
  0% { transform: scale(0.8); opacity: 0 }
  50% { transform: scale(1.2); opacity: 0.3 }
  100% { transform: scale(0.8); opacity: 0 }
}

/* Background Effects */
.modern-hero-glow {
  position: absolute;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(124,77,255,0.15) 0%, transparent 60%);
  filter: blur(80px);
  top: -50%;
  left: -20%;
  z-index: 1;
}

@media (max-width: 768px) {
  .modern-about-hero {
    padding: 4rem 1rem;
  }
  
  .modern-hero-gradient {
    font-size: 2.5rem;
  }
  
  .modern-hero-subtext {
    font-size: 1.5rem;
  }
  
  .client-scroll {
    animation: scroll 20s linear infinite;
  }
}


/* Value Grid */
.modern-value-grid {
  padding: 6rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
.modern-value-card h3{
  color: var(--modern-primary);
}

.modern-value-card {
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(0,230,184,0.1);
  transition: transform 0.3s ease;
}

.modern-value-card:hover {
  transform: translateY(-10px);
}

.modern-value-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Leadership Section */
.modern-leadership-section {
  padding: 6rem 2rem;
  background: linear-gradient(to bottom right, #0f172a, #1e293b);
}

.modern-leadership-profile {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.modern-leader-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0,230,184,0.1);
}

.modern-leader-bio {
  color: var(--modern-light);
}

.modern-leader-title {
  color: var(--modern-primary);
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.modern-leader-quote {
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 2rem 0;
}

/* Client Proof Section */
.modern-client-proof {
  padding: 6rem 2rem;
  background: var(--modern-light);
}

.modern-client-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 4rem;
}

.modern-client-logo-wall {
  width: 100%;
  height: auto;
  padding: 2rem;
  background: white;
  border-radius: 1rem;
}

.modern-client-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modern-stat-item {
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1);
}

.modern-stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, var(--modern-primary), #7c4dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Buttons */
.modern-cta-primary {
  background: var(--modern-primary);
  color: var(--modern-dark);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.modern-cta-primary:hover {
  transform: translateY(-3px);
}

.modern-cta-secondary {
  background: transparent;
  border: 2px solid var(--modern-primary);
  color: var(--modern-primary);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modern-hero-container,
  .modern-leadership-profile,
  .modern-client-grid {
    grid-template-columns: 1fr;
  }

  .modern-hero-headline {
    font-size: 2.5rem;
  }

  .modern-hero-year {
    font-size: 3.5rem;
  }

  .modern-leader-image {
    height: 400px;
  }
}
.modern-seo-content{
  display: none;
}
