/* Maintenance Plan Section */
.maintenance-plan-section {
  color: #e4e8eb;
  padding: 60px 20px;
  text-align: center;
}

/* Title and Subtitle */
.plan-title {
  text-align: center;
  font-size: 2em;
  color: #fff;
  margin-top: 4rem;
}

.plan-subtitle {
  font-size: 1em;
  color: #e4e8eb;
  text-align: center;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 30px;
  opacity: 0.9;
}

/* Plan Cards Container */
.plan-cards-container {
  display: flex;
  gap: 20px;
  padding: 2rem 0;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.plan-cards-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome and Edge */
}

/* Plan Card */
.plan-card {
  background: linear-gradient(180deg, #24303e, #1f2a37);
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  min-width: 300px;
  max-width: 350px;
  min-height: 400px;
  text-align: left;
  color: #e4e8eb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

/* Plan Price */
.plan-price {
  font-size: 1.8rem;
  font-weight: bold;
  color: #5fd0c5;
  margin-bottom: 1rem;
}

/* Features Table */
.features-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.features-table td {
  padding: 8px;
  text-align: left;
}

.features-table td:first-child {
  width: 70%;
}

.features-table td:last-child {
  text-align: center;
}

.checkmark {
  color: #5fd0c5;
  font-weight: bold;
}

.cross {
  color: #ff5c5c;
  font-weight: bold;
}

/* Unordered List (Legacy Support) */
ul {
  list-style-type: none;
  color: #d6d6d6;
  line-height: 1.6;
}

ul li {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/* Subscribe Button */
.subscribe-button {
  display: block;
  align-items: center;
  background: #5fd0c5;
  color: #101820;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  transition: background 0.3s, transform 0.2s;
}

.subscribe-button:hover {
  background: #89e3da;
  transform: translateY(-2px);
}

.subscribe-button:active {
  transform: translateY(1px);
  background: #71cec5;
}

/* Scroll Indicators */
.scroll-indicators {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.scroll-indicators .dot {
  width: 12px;
  height: 12px;
  background: #5fd0c5;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.scroll-indicators .dot.active {
  background: #ffffff;
  border: 2px solid #5fd0c5;
}

/* Responsive Design (Retain Horizontal Scroll for Mobile) */
@media (max-width: 768px) {
  .plan-cards-container {
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow-x: auto; /* Enable horizontal scroll */
  }

  .plan-card {
    flex: 0 0 calc(80% - 20px); /* Ensure cards fit within the screen width */
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .plan-title {
    font-size: 1.5em;
  }

  .plan-subtitle {
    font-size: 0.9em;
  }

  .plan-price {
    font-size: 1.5rem;
  }

  .subscribe-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .plan-card {
    flex: 0 0 calc(90% - 20px); /* Adjust card size for small screens */
    margin-right: 15px;
  }
}