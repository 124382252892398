:root {
    --primary-color: #00e6b8;
    --secondary-color: #7c4dff;
    --dark-bg: #0f172a;
    --text-light: #f8fafc;
  }
  
  .header {
    background: rgba(15, 23, 42, 0.95);
    backdrop-filter: blur(10px);
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 230, 184, 0.1);
  }
  
  .header.sticky {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    height: 35px;
  }
  
  .nav-list {
    display: flex;
    gap: 3.5rem;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    position: relative;
    list-style: none;
  }
  
  .nav-link {
    color: var(--text-light);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
    padding: 0.5rem 0;
  }
  
  .nav-link:hover {
    color: var(--primary-color);
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--dark-bg);
    border-radius: 0.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    min-width: 270px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 230, 184, 0.1);
  }
  
  .nav-item:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(5px);
  }
  
  .dropdown-item {
    display: block;
    color: var(--text-light);
    padding: 0.75rem 1rem;
    text-decoration: none;
    border-radius: 0.25rem;
    transition: all 0.2s ease;
    font-size: 0.95rem;
  }
  
  .dropdown-item:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  
  /* Mobile Styles */
  .hamburger {
    display: none;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .hamburger-line {
    display: block;
    width: 25px;
    height: 2px;
    background: var(--text-light);
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 300px;
    height: 100vh;
    background: var(--dark-bg);
    transition: right 0.3s ease;
    padding: 1rem;
    overflow-y: auto;
    border-left: 1px solid rgba(0, 230, 184, 0.1);
  }
  
  .mobile-menu.open {
    right: 0;
  }
  
  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .close-button {
    color: var(--text-light);
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 60px;
  }
  
  .mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .mobile-nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .mobile-nav-link {
    display: block;
    color: var(--text-light);
    padding: 1rem;
    text-decoration: none;
  }
  
  .accordion-header {
    width: 100%;
    background: none;
    border: none;
    color: var(--text-light);
    padding: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-content {
    padding-left: 1rem;
  }
  
  .mobile-subitem {
    display: block;
    color: var(--text-light);
    padding: 0.75rem 1rem;
    text-decoration: none;
    opacity: 0.8;
  }
  
  .header-cta-button {
    background: rgba(255,255,255,0.05);
  color: var(--primary-accent);
  border-radius: 0.5rem;
  text-decoration: none;
  border: 1px solid var(--primary-accent);
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .desktop-nav,
    .desktop-only {
      display: none;
    }
  
    .hamburger {
      display: block;
    }
    .header{
        padding: 0.5rem 1rem;
    }
    .mobile-logo {
        height: 40px;
    }
  }