:root {
  --primary-accent: #00e6b8;
  --secondary-accent: #7c4dff;
  --dark-bg: #0f172a;
  --card-bg: #1e293b;
  --text-primary: #f8fafc;
}

.testimonials-section {
  padding: 6rem 2rem;
  background: linear-gradient(to bottom right, var(--dark-bg) 60%, #0b1120);
  position: relative;
  overflow: hidden;
}

.testimonials-header {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto 4rem;
}

.testimonials-title {
  font-size: 2.75rem;
  background: linear-gradient(to right, var(--primary-accent), var(--secondary-accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.rating-badge {
  background: rgba(255,255,255,0.1);
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(10px);
}

.rating-stars {
  color: #ffd700;
}

.testimonials-subtitle {
  color: #94a3b8;
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.review-card {
  background: var(--card-bg);
  border-radius: 1.5rem;
  padding: 2rem;
  border: 1px solid rgba(255,255,255,0.1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.review-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, var(--primary-accent) 0%, transparent 70%);
  opacity: 0.1;
  transition: opacity 0.3s ease;
}

.review-card:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0 25px 50px -12px rgba(0, 230, 184, 0.1);
}

.review-card:hover::before {
  opacity: 0.2;
}

.review-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.author-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid var(--primary-accent);
}

.company-logo {
  height: 32px;
  width: auto;
  opacity: 0.8;
}

.review-author {
  color: var(--text-primary);
  margin: 0;
  font-size: 1.1rem;
}

.author-role {
  color: #94a3b8;
  font-size: 0.9rem;
  margin: 0;
}

.review-text {
  color: #cbd5e1;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.review-text::before {
  content: '“';
  position: absolute;
  left: -0.5rem;
  top: -1rem;
  font-size: 4rem;
  color: var(--primary-accent);
  opacity: 0.3;
  font-family: serif;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating {
  color: #ffd700;
  font-size: 1.2rem;
}

.cta-button {
  background: rgba(255,255,255,0.05);
  border: 1px solid rgba(255,255,255,0.1);
  color: var(--primary-accent);
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background: var(--primary-accent);
  color: var(--dark-bg);
  transform: translateX(0.25rem);
}

.trust-badges {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;
  opacity: 0.8;
}

.trust-badges img {
  height: 32px;
  filter: grayscale(1);
  transition: filter 0.3s ease;
}

.trust-badges img:hover {
  filter: grayscale(0);
}

@media (max-width: 768px) {
  .testimonials-title {
    font-size: 2rem;
    flex-direction: column;
  }
  
  .reviews-grid {
    grid-template-columns: 1fr;
  }
  
  .trust-badges {
    flex-wrap: wrap;
    justify-content: center;
  }
}