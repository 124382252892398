/* Welcome Section Background */
.welcome-section-background {
  background: linear-gradient(to right, #0f172a, #101820, #1e293b); /* Dark sleek gradient */
  color: #fff; /* White text for readability */
  padding: 1rem 0;
  overflow: hidden; /* Prevent scrollbars */
}

/* Welcome Container */
.welcome-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  height: calc(100vh - 80px); /* Full viewport height minus any header/footer */
  max-width: 1200px; /* Restrict width for better layout */
  margin: 0 auto; /* Center the container */
  padding: 4rem 2rem; /* Padding for spacing */
}

/* Hero Text */
.hero-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  color: #5fd0c5; /* Teal for the hero text */
  margin-top: 2rem; /* Adjusted top margin */
  line-height: 1.4; /* Better readability */
  font-weight: 700; /* Bold for emphasis */
}

/* Hero Paragraph */
.hero-p {
  color: #d6d6d6; /* Light gray text color for contrast */
  font-size: 0.8rem;
  line-height: 1.6;
  margin-top: 1.5rem;
}

/* Welcome Text Container */
.welcome-text {
  max-width: 700px;
  text-align: left; /* Align text to the left */
  margin-top: 2rem;
}

.hero-explore-button {
  background: var(--primary-accent);
  color: var(--dark-bg);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 600;
}
.explore-button:hover {
  background: linear-gradient(180deg, rgba(137, 227, 218, 1) 0%, rgba(95, 208, 197, 1) 100%);
  color: #101820;
  transform: translateY(-5px); /* More pronounced lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Added shadow for depth */
}

.hero-cta-container{
  display: flex;
  gap: 1.5rem;
}

.secondary-hero-explore-button{
  background: rgba(255,255,255,0.05);
  color: var(--primary-accent);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  border: 1px solid var(--primary-accent);
}

/* Welcome Image */
.welcome-image img {
  max-width: 600px; /* Adjusted for better layout */
  height: auto; /* Maintain aspect ratio */
  display: inline-block; /* Ensure proper alignment */
  transform-origin: center; /* Pivot for rotation or transformation */
  border-radius: 10px; /* Slight rounding for a modern feel */
  opacity: 0; /* Initially hidden for lazy loading */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for fade-in effect */
}

.welcome-image img[data-loaded="true"] {
  opacity: 1; /* Fully visible when loaded */
}


/* Responsive Design */
@media (max-width: 1068px) {
  .welcome-container {
    flex-direction: column;
    text-align: center; /* Center-align text for smaller screens */
    gap: 3rem;
    height: auto; /* Adjust height for smaller screens */
    padding: 2rem;
  }

  .hero-text {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .welcome-image img {
    max-width: 350px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .welcome-container {
    padding: 1rem;
    margin-top: 0;
  }

  .hero-text {
    font-size: 2rem;
    line-height: 1.3;
    max-width: 340px;
  }

  .hero-p {
    font-size: 1rem;
    line-height: 1.5;
  }



  .welcome-image img {
    max-width: 300px;
    margin-bottom: 6rem;
  }
  .stats-bar {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .welcome-container {
    padding: 1rem;
    text-align: center;
  }

  .hero-text {
    font-size: 1.9rem;
  }

  .hero-p {
    font-size: 0.8rem;
  }
  .hero-cta-container{
    flex-direction: column;
    gap: 1.5rem;
  }



  .welcome-image img {
    max-width: 280px;
  }
}
/* Added New Styles */
.highlight {
  color: #5fd0c5;
  display: block;
}

.usp-list {
  margin: 2rem 0;
}

.usp-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  color: #e4e8eb;
}

.usp-icon {
  font-size: 1.5rem;
}

.hero-cta-container {
  border-top: 2px solid #24303e;
  padding-top: 2rem;
}

.trust-badges {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}
.hero-explore-button {
  background: #5fd0c5;
  color: #1f2a37;
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}


.trust-badges .badge {
  color: #d4d4d4;
  font-size: 0.9rem;
}

.trust-badges img {
  height: 50px;
  width: auto;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.trust-badges img:hover {
  opacity: 1;
}

.review-widget {
  background: #1a242f;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Modified Explore Button */

/* Media Queries */
@media (max-width: 768px) {
  .hero-text {
    font-size: 1.6rem;
  }
  
  .usp-item {
    font-size: 0.9rem;
  }

  .explore-button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .hero-text {
    font-size: 1.4rem;
    line-height: 1.2;
  }

  .usp-item {
    font-size: 0.85rem;
    margin: 0.8rem 0;
  }

  .usp-icon {
    font-size: 1.5rem;
  }

  .review-widget {
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .trust-badges img {
    height: 35px;
  }

  .explore-button {
    font-size: 0.85rem;
    max-width: 250px;
  }

  .welcome-container {
    padding: 1rem 0.5rem;
  }
}

@media (max-width: 360px) {
  .hero-text {
    font-size: 1.3rem;
  }

  .usp-item {
    font-size: 0.8rem;
  }

  .explore-button {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}

:root {
  --primary-color: #00e6b8;
  --secondary-color: #7c4dff;
  --dark-bg: #0f172a;
  --card-bg: #1f2a37;
  --text-light: #f8fafc;
}

/* Marketing Hero Section */
.marketing-hero {
  padding: 2rem 2rem 4rem;
  background: linear-gradient(to bottom right, #0f172a, #1a2337);
  position: relative;
  overflow: hidden;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
}

/* Text Content Styles */
.hero-heading {
  font-size: 3.5rem;
  color: var(--text-light);
  line-height: 1.2;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.highlight {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subheading {
  font-size: 1.25rem;
  color: #94a3b8;
  max-width: 600px;
  margin-bottom: 3rem;
}

/* CTA Buttons */
.cta-container {
  display: flex;
  
  gap: 1.5rem;
  margin-bottom: 4rem;
}



.service-cta:hover {
  background: #7be3d9;
  transform: scale(1.05);
}




.welcome-pricing-hero-container{
  padding: 2rem 2rem 4rem;
  background: linear-gradient(to bottom right, #0f172a, #1a2337);
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.pricing-cta-container{
  display: flex;
  
  gap: 1.5rem;
  margin-bottom: 4rem;
  justify-content: center;
}

.maintenance-teaser {
  background: rgba(255,255,255,0.03);
  padding: 3rem 2rem;
  margin-top: 4rem;
  border-radius: 1rem;
}
.maintenance-teaser h2, p, h4{
  color:whitesmoke;
  margin-bottom: 1.5rem;
}

.teaser-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 800px;
  margin: 2rem auto;
}

.teaser-card {
  background: #1e293b;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
}

.teaser-price {
  color: #00e6b8;
  font-size: 1.25rem;
  margin: 1rem 0;
}

.teaser-cta {
  background: rgba(0,230,184,0.1);
  color: #00e6b8;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  width: 100%;
}


@media (max-width: 768px) {
  .teaser-cards {
    grid-template-columns: 1fr;
    padding: 0;
  }
  
  .preview-cards {
    grid-template-columns: 1fr;
    padding: 0;
  }

}



.stats-bar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 3rem;
  background: linear-gradient(135deg, #0f172a, #1e293b);
  border-radius: 1rem;
  margin: 4rem 0;
  position: relative;
  overflow: hidden;
  margin-top: -2rem;
}

.stats-bar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    rgba(0,230,184,0.1) 0%,
    rgba(124,77,255,0.1) 100%
  );
  z-index: 0;
}

.stat-item {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 1.5rem;
  background: rgba(255,255,255,0.03);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}

.stat-item:hover {
  transform: translateY(-5px);
  background: rgba(0,230,184,0.05);
}

.stat-icon {
  font-size: 2rem;
  color: #00e6b8;
  margin-bottom: 1rem;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #00e6b8, #7c4dff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0.5rem 0;
}

.stat-label {
  color: #94a3b8;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.stat-hover {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(0,230,184,0.1);
  transition: all 0.3s ease;
}

.stat-item:hover .stat-hover {
  bottom: 0;
}


@media (max-width: 768px) {
  .stats-bar {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    gap: 1rem;
  }
  
  .stat-number {
    font-size: 2rem;
  }
}





/* Services Section */
#services-section {
  padding: 6rem 2rem; /* Adjusted padding for better spacing */
  background-color: #101820; /* Dark background for contrast */
  color: #fff; /* White text for readability */
}

/* Section Title */
.section-title {
  text-align: center;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 3rem;
  font-family: 'Montserrat', sans-serif; /* Modern font */
  font-weight: 700; /* Bold for emphasis */
}

.service-cta {
  align-self: flex-start;
  background: #5fd0c5;
  color: #1f2a37;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.service-cta:hover {
  background: #7be3d9;
  transform: scale(1.05);
}

/* Creative Grid */
.creative-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 2rem; /* Spacing between items */
  max-width: 1200px;
  margin: 0 auto; /* Center the grid */
}

/* Creative Item */
.creative-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: #1f2a37; /* Dark card background */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border for definition */
}

.creative-item:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}

/* Creative Background */
.creative-background {
  height: 200px;
  background-size: cover;
  background-position: center;
  opacity: 0.8; /* Slightly transparent for overlay effect */
  transition: opacity 0.3s ease;
}

.creative-item:hover .creative-background {
  opacity: 1; /* Full opacity on hover */
}

/* Creative Content */
.creative-content {
  padding: 2rem;
  background: rgba(31, 42, 55, 0.9); /* Semi-transparent dark background */
  backdrop-filter: blur(5px); /* Blur effect for modern look */
}

.creative-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #5fd0c5; /* Teal color for titles */
  font-family: 'Montserrat', sans-serif;
  font-weight: 600; /* Semi-bold for emphasis */
}

.creative-description {
  font-size: 1rem;
  color: #d6d6d6; /* Light gray for readability */
  line-height: 1.6; /* Improved readability */
}

/* Responsive Adjustments */
@media (max-width: 1068px) {
  #services-section {
    padding: 4rem 2rem; /* Adjusted padding for smaller screens */
  }

  .section-title {
    font-size: 2rem; /* Smaller font for title */
  }

  .creative-grid {
    gap: 1.5rem; /* Reduced gap for smaller screens */
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.8rem; /* Smaller font for title */
  }

  .creative-item {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .creative-title {
    font-size: 1.3rem; /* Adjust title size for readability */
  }

  .creative-description {
    font-size: 0.9rem; /* Adjust text size for readability */
  }
}

@media (max-width: 480px) {
  #services-section {
    padding: 2rem 1rem; /* Adjusted padding for mobile */
  }

  .section-title {
    font-size: 1.5rem; /* Smaller font for title */
  }

  .creative-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .creative-item {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  .creative-title {
    font-size: 1.2rem; /* Adjust title size for readability */
  }

  .creative-description {
    font-size: 0.85rem; /* Adjust text size for readability */
  }
}

/* ===== Process Section ===== */
.process-section {
  padding: 4rem 2rem;
  background: #f9fafb;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.process-step {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  text-align: center;
}

.step-number {
  width: 40px;
  height: 40px;
  background: #5fd0c5;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-weight: 600;
}

/* ===== Testimonials ===== */
.testimonials {
  padding: 4rem 2rem;
  background: #1f2a37;
  color: white;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.testimonial-card {
  background: rgba(255,255,255,0.1);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
}

.client-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  background-size: cover;
}

.rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-top: 1rem;
}

/* ===== Tech Stack ===== */
.tech-stack {
  padding: 4rem 2rem;
  text-align: center;
  background: #fff;
}

.tech-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.tech-logos img {
  height: 50px;
  width: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.tech-logos img:hover {
  opacity: 1;
}

/* ===== FAQ Section ===== */
.faq-section {
  padding: 4rem 2rem;
  background: #f9fafb;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.faq-item {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.faq-item h3 {
  color: #1f2a37;
  margin-bottom: 1rem;
}

.faq-cta {
  text-align: center;
  margin-top: 2rem;
}

/* ===== Blog Preview ===== */
.blog-preview {
  padding: 4rem 2rem;
  background: #fff;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.blog-post {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.blog-post h3 {
  color: #1f2a37;
  margin-bottom: 1rem;
}

