/* QuoteForm.css */
.modern-quote-section {
    background: linear-gradient(135deg, #0f172a, #1e293b);
    color: #f8fafc;
    min-height: 100vh;
    padding: 4rem 1rem;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

.quote-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 3rem;
    position: relative;
}

.quote-header {
    text-align: center;
    margin-bottom: 3rem;
    grid-column: 1 / -1;
}

.quote-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, #00e6b8, #7c4dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quote-steps {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.input-group {
    position: relative;
}

.input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #94a3b8;
    font-size: 0.9rem;
}

.input-group input {
    width: 100%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    color: #fff;
    transition: all 0.3s ease;
    -webkit-appearance: none;
}

.input-group input:focus {
    outline: none;
    border-color: #00e6b8;
    box-shadow: 0 0 0 3px rgba(0, 230, 184, 0.1);
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    gap: 1rem;
}

.next-btn, .back-btn, .payment-btn {
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    touch-action: manipulation;
}

.next-btn {
    background: linear-gradient(45deg, #00e6b8, #7c4dff);
    color: #0f172a;
}

.next-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 230, 184, 0.3);
}

.back-btn {
    background: none;
    color: #94a3b8;
    border: 1px solid rgba(148, 163, 184, 0.3);
}

.back-btn:hover {
    color: #00e6b8;
    border-color: #00e6b8;
}

.service-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.service-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid transparent;
    position: relative;
    overflow: hidden;
}

.service-card:hover {
    background: rgba(255, 255, 255, 0.08);
}

.service-card.selected {
    border-color: #00e6b8;
    background: rgba(0, 230, 184, 0.05);
}

.service-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #00e6b8, #7c4dff);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.service-card.selected::after {
    opacity: 1;
}

.option-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 1rem;
    margin: 1.5rem 0;
}

.option-card {
    padding: 1.5rem 1rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 0.5rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid transparent;
}

.option-card:hover {
    transform: translateY(-3px);
}

.option-card.selected {
    background: #00e6b8;
    color: #0f172a;
    border-color: #00e6b8;
    box-shadow: 0 5px 15px rgba(0, 230, 184, 0.3);
}

.price-summary {
    text-align: center;
    margin: 2rem 0;
}

.security-badges {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin: 2rem 0;
    flex-wrap: wrap;
}

.security-badges img {
    width: 100px;
    height: auto;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.security-badges img:hover {
    opacity: 1;
}

.quote-sidebar {
    position: sticky;
    top: 2rem;
    height: fit-content;
}

.trust-card {
    background: rgba(0, 230, 184, 0.05);
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(0, 230, 184, 0.1);
}

.trust-card h4 {
    color: #00e6b8;
    margin-bottom: 1rem;
}

.trust-card ul {
    list-style: none;
    padding: 0;
}

.trust-card li {
    margin-bottom: 1rem;
    padding: 0.8rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 0.5rem;
}

.client-testimonial {
    background: rgba(255, 255, 255, 0.03);
    padding: 1.5rem;
    border-radius: 1rem;
    border-left: 4px solid #00e6b8;
}

.client-testimonial p {
    font-style: italic;
    margin-bottom: 0.5rem;
}

.client-testimonial span {
    color: #94a3b8;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .modern-quote-section {
        padding: 2rem 1rem;
    }

    .quote-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0;
    }

    .quote-steps {
        order: 2;
    }

    .quote-sidebar {
        order: 1;
        position: relative;
        top: auto;
        margin-bottom: 1rem;
    }

    .quote-header h1 {
        font-size: 1.8rem;
        line-height: 1.3;
    }

    .form-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .service-cards {
        grid-template-columns: 1fr;
    }

    .option-grid {
        grid-template-columns: 1fr;
    }

    .button-group {
        flex-direction: column-reverse;
    }

    .next-btn, .back-btn {
        width: 100%;
        justify-content: center;
    }
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.loading {
    animation: pulse 1.5s infinite;
}

/* Mobile-Specific Fixes */
@media (max-width: 480px) {
    .input-group input {
        font-size: 16px;
    }

    .service-card {
        padding: 1.5rem;
    }

    .price-summary .total {
        font-size: 2.5rem;
    }

    .security-badges img {
        width: 80px;
    }
}

body {
    overflow-x: hidden;
}

.quote-container * {
    max-width: 100%;
}